import Link from 'next/link';
import { memo, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { ADS_DATA } from '@/types/interfaces';

import HomeAdsItem from './HomeAdsItem';

const TopPage = ({ adsData }: { adsData: ADS_DATA[] }) => {
  const children = useMemo(() => {
    return (
      <>
        {adsData?.map((ads: ADS_DATA) => (
          <Col key={ads.id} md={3}>
            <HomeAdsItem adsDetail={ads} />
          </Col>
        ))}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adsData]);
  return (
    <div className="our-top-wrap">
      <Container>
        <h2>Top Premium Call Girls In India</h2>
        <div className="top-inner-wrap">
          <Row className="top-cg-row">{children}</Row>
          <div className="viewMore">
            <Link href={'/top-rated-call-girl'} className="btn_primary">
              View More
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default memo(TopPage);
