import Head from 'next/head';

import Home from '@/Layout/Home';
import { getTopRateListAndBlog } from '@/Layout/Home/service';
import { REQUEST } from '@/types/interfaces';

export async function getServerSideProps({ req }: any) {
  console.log(req.headers);
  const ress = (await getTopRateListAndBlog()) as REQUEST;
  const listData = ress?.data;

  return {
    props: {
      listData,
    },
  };
}
const Index = ({ listData }: any) => {
  const homePage = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '247Torax #1 Verified Escort & Call Girl Website in India 2024',
    description:
      '247Torax is India’s top verified escorts and call girl website for 2024. Find trusted call girls with verified profiles for safe, secure, and reliable services.',
    url: process.env.REACT_BASE_URL,
  };
  return (
    <>
      <Head>
        <title>{'247Torax #1 Verified Escort & Call Girl Website in India 2024'}</title>
        <meta name="title" content={'247Torax #1 Verified Escort & Call Girl Website in India 2024'} />
        <meta
          name="description"
          content={
            '247Torax is India’s top verified escorts and call girl website for 2024. Find trusted call girls with verified profiles for safe, secure, and reliable services.'
          }
        />
        <meta
          name="keyword"
          content={
            'Call girl Chennai, Call girl in Bangalore, Hyderabad call girl, Escort service Jaipur, Call girl Pune, Call girl in Mumbai, Udaipur call girl, Escort service Jaipur'
          }
        />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(homePage) }}></script>
      </Head>

      <Home listData={listData} />
    </>
  );
};
Index.auth = false;
Index.meta = {
  siteUrl: 'https://247torax.com',
  locale: 'en_us',
  ogTitle: 'Adult Classified site in India - 247torax',
  ogType: 'website',
  ogUrl: 'https://247torax.com',
  siteName: '247torax',
  ogDescription:
    'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
};
export default Index;
